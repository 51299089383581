import React from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '.6rem 0',
    textAlign: 'left',
  },
}))

const Input = (props) => {
  let inputElement = null
  const classes = useStyles()

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <TextField
          className={props.customClass}
          label={props.placeholder}
          value={props.value}
          onChange={props.changed}
        />
      )
      break
    case 'select':
      inputElement = (
        <FormControl className={classes.formControl}>
          <InputLabel id="select-label">{props.placeholder}</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={props.value}
            onChange={props.changed}
          >
            {props.domains.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
      break
    default:
      inputElement = (
        <TextField
          label={props.placeholder}
          value={props.value}
          onChange={props.changed}
        />
      )
  }
  return inputElement
}

export default Input
