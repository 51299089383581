import React from 'react'
import axios from '../../axios-services'
import MaterialTable from 'material-table'
import { generateQrCode } from '../../shared/utility'

class LinksList extends React.Component {
  state = {
    searchValue: '',
    responseData: '',
  }

  linkSearch = (linkValue) => {
    var urlArray = linkValue.split('/')
    let auth = localStorage.getItem('netaAuth')
    auth = JSON.parse(auth)

    let url = '/short-links?domain=' + urlArray[0] + '&token=' + urlArray[1]
    axios
      .get(`${url}`, { headers: { Authorization: 'Bearer ' + auth.token } })
      .then((response) => {
        this.setState({ responseData: response.data.data })
      })
  }

  changeInputHandler = (event) => {
    this.setState({ searchValue: event.target.value })
    this.linkSearch(event.target.value)
  }

  render() {
    let newData

    !this.state.responseData
      ? (newData = (query) =>
          new Promise((resolve, reject) => {
            let auth = localStorage.getItem('netaAuth')
            auth = JSON.parse(auth)

            let url = '/short-links?'
            url += 'page=' + (query.page + 1)
            url += '&per_page=' + query.pageSize
            axios
              .get(`${url}`, {
                headers: { Authorization: 'Bearer ' + auth.token },
              })
              .then((response) => {
                resolve({
                  data: response.data.data,
                  page: response.data.meta.pagination.current_page - 1,
                  totalCount: response.data.meta.pagination.total,
                })
              })
          }))
      : (newData = this.state.responseData)

    return (
      <>
        <MaterialTable
          title="Links"
          options={{
            search: false,
            headerStyle: {
              padding: '10px 15px',
            },
            pageSize: 10,
            editTooltip: 'sdfdd',
          }}
          columns={[
            { title: 'Id', field: 'id', editable: 'never' },
            {
              title: 'Url',
              field: 'url',
              cellStyle: {
                wordBreak: 'break-all',
                minWidth: '400px',
              },
              editComponent: (props) => (
                <input
                  type="text"
                  style={{
                    width: '100%',
                    font: 'inherit',
                    fontSize: '0.875rem',
                    border: 'none',
                    borderBottom: '1px solid #000',
                  }}
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                />
              ),
            },
            { title: 'Short Url', field: 'short_url', editable: 'never' },
            { title: 'Visits', field: 'stats.visits.total', editable: 'never' },
            {
              title: 'Created at',
              headerStyle: {
                minWidth: '125px',
              },
              field: 'created_at',
              editable: 'never',
              render: (rowData) => {
                let date = new Date(Date.parse(rowData.created_at))
                return <p>{date.toLocaleString()}</p>
              },
            },
          ]}
          data={newData}
          actions={[
            {
              icon: () => {
                return (
                  <img
                    style={{ width: '1em' }}
                    src={require('../../assets/images/qr-code.svg')}
                    alt="qr-code-icon"
                  />
                )
              },
              tooltip: 'Download QR code',
              onClick: (event, rowData) => {
                generateQrCode(rowData.short_url)
              },
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                let auth = localStorage.getItem('netaAuth')
                auth = JSON.parse(auth)

                axios
                  .patch(`/short-links/${newData.id}`, newData, {
                    headers: { Authorization: 'Bearer ' + auth.token },
                  })
                  .then((response) => {
                    resolve()
                  })
                  .catch((error) => {})
              }),
          }}
          localization={{
            body: {
              editTooltip: 'Edit url',
            },
          }}
        />
        <div hidden>
          <canvas id="canvas" align="center" />
        </div>
      </>
    )
  }
}

export default LinksList
