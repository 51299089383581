import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListIcon from '@material-ui/icons/List'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import LinkIcon from '@material-ui/icons/Link'
import NetaAuth from '../../services/neta-auth'

const service = new NetaAuth()

const onLogout = (e) => {
  e.stopPropagation()
  service.logout()
}

export const navigationItems = (
  <div>
    <ListItem button>
      <ListItemIcon>
        <ListIcon />
      </ListItemIcon>
      <Link to="/links" style={{ textDecoration: 'none', color: '#000' }}>
        <ListItemText primary="All links" />
      </Link>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LinkIcon />
      </ListItemIcon>
      <Link
        to="/links/create"
        style={{ textDecoration: 'none', color: '#000' }}
      >
        <ListItemText primary="Create short link" />
      </Link>
    </ListItem>
  </div>
)

export const secondaryNavigationItems = (
  <div>
    <ListItem button onClick={onLogout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
)
