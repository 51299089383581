import React, { Component } from 'react'
import NetaAuth from '../../services/neta-auth'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Box } from '@material-ui/core'

const service = new NetaAuth()

class CallbackPage extends Component {
  state = {}

  componentWillMount() {
    service.callback()
    this.props.history.push('/')
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box mt={4}>
          <CircularProgress />
        </Box>
      </Container>
    )
  }
}

export default withRouter(CallbackPage)
