import axios from 'axios'

const apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://url-shortener.prod.neta.sh/api'
    : 'https://url-shortener.staging.neta.sh/api'

const instance = axios.create({
  baseURL: apiUrl,
})

export default instance
