import React from 'react'
import './App.css'
import LoginPage from './containers/Auth/LoginPage'
import CallbackPage from './containers/Auth/CallbackPage'
import { BrowserRouter, Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Layout from './containers/Layout/Layout'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route path="/" render={() => <Redirect to="/links" />} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/callback" exact component={CallbackPage} />

        <PrivateRoute path="/links" component={Layout} />
      </div>
    </BrowserRouter>
  )
}

export default App
