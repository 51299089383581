const queryString = require('querystring')

const authServerUrl = 'https://auth.ops.neta.sh'
let appUrl
let authServerClientId

switch (process.env.NODE_ENV) {
  case 'production':
    appUrl = 'https://url-shortener.prod.neta.sh'
    authServerClientId = 'a8ce5eb1-989e-4267-b9fa-02c272262689'
    break
  case 'staging':
    appUrl = 'https://url-shortener.staging.neta.sh'
    authServerClientId = 'ef23d5fa-4708-4e51-bcbb-6e2bbce86adb'
    break
  default:
    appUrl = 'http://localhost:3000'
    authServerClientId = 'c28c6e82-3e31-4df1-adec-4a6bc12a4f97'
}

const authUrl = `${authServerUrl}/oauth/authorize`

export default class NetaAuth {
  login = () => {
    const query = queryString.stringify({
      client_id: authServerClientId,
      redirect_uri: `${appUrl}/callback`,
      response_type: 'token',
      scope: '',
    })

    window.location = `${authUrl}?${query}`
  }

  // parses the result after authentication from URL hash
  callback = () => {
    const params = queryString.parse(window.location.hash.substr(1))

    if (!params.access_token) {
      alert('Authentication error. Try again later.')
      this.logout()
      return false
    }

    let expiresAt = JSON.stringify(
      params.expires_in * 1000 + new Date().getTime()
    )
    localStorage.setItem(
      'netaAuth',
      JSON.stringify({
        token: params.access_token,
        expiresAt: expiresAt,
      })
    )

    return true
  }

  logout = () => {
    localStorage.removeItem('netaAuth')
    window.location = `/`
    return true
  }

  isLoggedIn = () => {
    let auth = localStorage.getItem('netaAuth')
    if (!auth) {
      return false
    }
    try {
      auth = JSON.parse(auth)
    } catch (e) {
      return false
    }
    if (!auth.expiresAt || auth.expiresAt < new Date().getTime()) {
      return false
    }
    return !!auth.token
  }
}
