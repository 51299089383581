import NetaAuth from '../../services/neta-auth'
import { Route, Redirect } from 'react-router-dom'
import React from 'react'

const auth = new NetaAuth()

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isLoggedIn() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
)
